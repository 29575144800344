import React, { useEffect } from "react";
import Banner from "./banner";
import GrowYourBusiness from "./growYourBusiness";
import Fusion from "./fusion";
import OnlineCourses from "./onlineCourses";

export default props => {
    useEffect(() => {
        window.scrollTo({ top: 0 });
    }, []);
    return (
        <div className="coursesPage" style={{ width: "100%" }}>
            <Banner />
            <GrowYourBusiness />
            <Fusion />
            <OnlineCourses />
        </div>
    );
};
