import React from 'react';
import './style.scss';

const Fusion = (props) => {
  return (
    <div className='fusion' onLoad={(a) => console.log(a)}>
      <div className='section-heading'>
        <h1 className='title'>THRIVE STATE, WHAT IS IT?</h1>
        <div className='text-wrapper'>
          <p className='text-left'>
            When we say Thrive State, we are referring to the condition of our clients. Their
            physiology AND psychology. Their mind, Their body, Their emotions, Their feelings...
            themselves at a cellular level. The organism that is our client. MIND BODY ENERGY
            <br />
            <br />
            What they eat and drink, their training, their thoughts, their relationships, their
            conversations, their sleep, their observations, their perceptions, their beliefs... all
            impact their Thrive State in various ways.
            <br />
            <br />
            Coaches if we are not wise with our decisions around training our clients, taking into
            consideration ALL the factors that influence their state, we may not be getting the
            results we’re seeking. We may be creating different adaptations. We may even be
            encouraging a state of disfunction for our clients.
            <br />
            <br />
            Our world class online courses and workshops have been designed for Personal Trainers to
            develop an understanding of what Thrive State is and to provide their clients with the
            necessary tools to achieve their goals but also to perform at their highest level in the
            gym and in life.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Fusion;
