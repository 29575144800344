export const para1 = `Our Thrive Nation, Thrive Business series is all about
    creating and sustaining a successful Personal Training
    business. Thrive Business course will take you from
    simple business fundamentals such as budgeting, creating
    a business plan, registering the business, all the way
    through to the latest sales and marketing strategies, to
    not only create abundant leads to your business but to
    close the sale as well.`
export const para2 = `When we say Thrive State, we are referring to the condition of our clients. Their physiology AND psychology. Their mind, Their body, Their emotions, Their feelings... themselves at a cellular level. The organism that is our client. MIND BODY ENERGY
    What they eat and drink, their training, their thoughts, their relationships, their conversations, their sleep, their observations, their perceptions, their beliefs... all impact their Thrive State in various ways.
    Coaches if we are not wise with our decisions around training our clients, taking into consideration ALL the factors that influence their state, we may not be getting the results we’re seeking. We may be creating different adaptations. We may even be encouraging a state of disfunction for our clients.
    Our world class online courses and workshops have been designed for Personal Trainers to develop an understanding of what Thrive State is and to provide their clients with the necessary tools to achieve their goals but also to perform at their highest level in the gym and in life.`
export const para3 = `The course is the first in a series designed to educate
    personal trainers on how to see incorrect movement and
    correct it with smart easy to apply movement cues. This
    course promotes the progressive loading of exercise
    prescription within a strength training environment
    based on an individual's movement competency and
    movement capacity. In other words, a person should be
    loaded based on how they understand how to use their
    body within each of the seven fundamental movement
    patterns. This approach will help to ensure that the
    physical stimulus that is prescribed contributes more to
    that person's performance objectives than the mechanisms
    that contribute to chronic and acute soft tissue
    injuries.`
