import React, { useState, useImperativeHandle, forwardRef } from "react";
import PropTypes from "prop-types";
import { checkNumber } from "./validator";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./style.scss";

const InputWrapper = forwardRef((props, ref) => {
    const [error, setError] = useState(null);

    const valueChanged = e => {
        let val = e.target.value;
        props.onChange(val);
        validate(val);
    };

    useImperativeHandle(ref, () => ({
        validateInput: v => {
            return validate(v);
        }
    }));

    const validate = val => {
        if (props.isNumber && !checkNumber(val)) {
            setError("Invalid Number");
            return false;
        }
        if (props.required && !val) {
            setError("Required");
            return false;
        }
        setError("");
        return true;
    };

    return (
        <div className={"input-wrapper " + (error && "error")}>
            <label htmlFor={props.name} className="label">
                {props.label}
            </label>
            <input
                id={props.name}
                className="input"
                type={props.type}
                placeholder={props.placeholder}
                value={props.value}
                required={props.required}
                onChange={e => valueChanged(e)}
            />
            {error ? (
                <div className="error">
                    <span className="error-text">{error}</span>
                    <FontAwesomeIcon
                        className="error-icon"
                        icon={faExclamationTriangle}
                    />
                </div>
            ) : null}
        </div>
    );
});

InputWrapper.defaultProps = {
    inputType: "text"
};

InputWrapper.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    type: PropTypes.string,
    placeholder: PropTypes.any,
    value: PropTypes.any,
    required: PropTypes.bool,
    isNumber: PropTypes.bool,
    onChange: PropTypes.func.isRequired
};

export default InputWrapper;
