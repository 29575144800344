import React from "react"
import "./style.scss"
const OurSchools = (props) => {
  return (
    <div className="ourSchools">
      <div className="section-heading">
        <h2 className="title">Thrive nation in schools</h2>
        <p>
          “Our Thrive Nation in schools programme aims to provide the opportunity for young people to understand their
          movement, energy and mindfulness patterns for life. Our integrated system of mind - body - energy aims to
          improve the physical literacy and overall wellbeing of young kiwis (change to people for international) as a
          part of their school curriculum."
        </p>
      </div>
    </div>
  )
}

export default OurSchools
