import React from "react";
import ReactGA from "react-ga";
import { createBrowserHistory } from "history";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import TopNav from "./wrappers/topNav";
import Contact from "./wrappers/contact";
import Footer from "./wrappers/footer";

import Home from "./pages/home";
import Courses from "./pages/courses";
import Schools from "./pages/schools";
import Corporate from "./pages/corporate";

const history = createBrowserHistory();
const trackingId = "UA-175301098-2";
ReactGA.initialize(trackingId);
history.listen((location) => {
    ReactGA.set({ page: location.pathname }); // Update the user's current page
    ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

export default function App() {
    return (
        <Router history={history}>
            <TopNav />
            <Switch>
                <Route exact path="/" component={Home} />
                <Route path="/Courses" component={Courses} />
                <Route path="/Schools" component={Schools} />
                <Route path="/Corporate" component={Corporate} />
            </Switch>
            <Contact />
            <Footer />
        </Router>
    );
}
