import React from "react"
import "./style.scss"
import { Link } from "react-router-dom"

const IntegratedApproachSection = (props) => {
  return (
    <div className="integratedApproachSection">
      <div className="left-section">
        <div className="section-heading">
          <h2 className="title">An integrated approach to optimal human performance</h2>
          <p className="white-text">
            By training your body, increasing your energy and engaging your mind, with our safe, effective and
            structured program, we aim to uncover the athlete in everyone. Whether you're a school looking to implement
            a physical literacy programme, a corporation wanting to engage in a wellness solution for your staff or a
            fitness professional seeking ongoing professional development, Thrive Nation has the tools for you.
          </p>
        </div>
      </div>
      <div className="right-section">
        <Link to={"/schools"} className="box box-1">
          <div className="title">
            <p>SCHOOLS</p>
          </div>
        </Link>
        <Link to="/corporate" className="box box-2">
          <div className="title">
            <p>CORPORATE</p>
          </div>
        </Link>
        <Link to="/courses" className="box box-3">
          <div className="title">
            <p>PERSONAL TRAINERS</p>
          </div>
        </Link>
      </div>
    </div>
  )
}

export default IntegratedApproachSection
