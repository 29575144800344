import React, { useState } from 'react';
import { para1, para2, para3 } from './paragraphs';
import './style.scss';

const IntegratedApproachSection = () => {
  const [para1More, setPara1More] = useState(false);
  const [para2More, setPara2More] = useState(false);
  const [para3More, setPara3More] = useState(false);

  return (
    <div className='integratedApproachSection'>
      <div className='section-heading'>
        <h1 className='title'>ONLINE COURSES</h1>
      </div>

      <div className='box-sections'>
        <div className='box box-2'>
          <div className='section-heading'>
            <h2 className='title white-text'>THRIVE STATE</h2>
            {para2More ? (
              <p className='text-left'>{para2}</p>
            ) : (
              <p className='text-left'>
                {para2.substr(0, 300)}{' '}
                <span onClick={() => setPara2More(true)} className='readMore'>
                  ..read more
                </span>
              </p>
            )}
          </div>
        </div>
        <div className='box box-3'>
          <div className='section-heading'>
            <h2 className='title white-text'>MOVEMENT MATTERS MOST</h2>
            {para3More ? (
              <p className='text-left'>{para3}</p>
            ) : (
              <p className='text-left'>
                {para3.substr(0, 300)}{' '}
                <span onClick={() => setPara3More(true)} className='readMore'>
                  ..read more
                </span>
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default IntegratedApproachSection;
