import React from "react"
import "./style.scss"

const WhatIsANSection = () => {
  return (
    <div className="whatIsSection">
      <div className="left-section"></div>
      <div className="right-section">
        <div className="section-heading">
          <h2 className="title">What is Thrive Nation</h2>
          <p>
            Thrive Nation is a world class integrated Physical Health and Wellness program that enhances your human
            performance covering three areas; Mind, Body and Energy.”
          </p>
        </div>
      </div>
    </div>
  )
}

export default WhatIsANSection
