import React, { useState, useEffect } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { imageRequest } from '../../helper'
import './style.scss'

const TopNav = ({ location }) => {
  const [open, setOpen] = useState(false)
  const [scroll, setScroll] = useState(window.scrollY)

  useEffect(() => {
    window.addEventListener('scroll', updateWindowScroll)
    return () => window.removeEventListener('scroll', updateWindowScroll)
  }, [])

  const updateWindowScroll = () => {
    setScroll(window.scrollY)
  }

  let loc = location.pathname
  let blackBack = scroll > 70 ? 'black-back' : ''
  let openClass = open ? 'showing' : ''

  const imgR = imageRequest('logo.png', 100)
  return (
    <div className='top-nav-wrapper'>
      <div className={'top-nav ' + openClass + ' ' + blackBack}>
        <div className={'nav-items-wrapper '}>
          <div className={'nav-items'}>
            <div className='spacer'></div>

            <div onClick={() => setOpen(!open)} className={'item ' + (loc === '/' ? 'selected' : null)}>
              <Link to={'/'}>
                <p>Home</p>
              </Link>
            </div>

            <div onClick={() => setOpen(!open)} className={'item ' + (loc === '/schools' ? 'selected' : null)}>
              <Link to={'/schools'}>
                <p>schools</p>
              </Link>
            </div>
            <div onClick={() => setOpen(!open)} className={'item ' + (loc === '/corporate' ? 'selected' : null)}>
              <Link to={'/corporate'}>
                <p>corporate</p>
              </Link>
            </div>
            <div onClick={() => setOpen(!open)} className={'item ' + (loc === '/courses' ? 'selected' : null)}>
              <Link to={'/courses'}>
                <p>courses</p>
              </Link>
            </div>
            <div className={'item'}>
              <a href='https://app.thrivenation.co.nz'>
                <p>Login</p>
              </a>
            </div>
          </div>
        </div>

        <div className='nav-top-left'>
          <Link className='logo-wrapper' to={'/'}>
            <img src={imgR} alt='logo' className='logo' />
          </Link>{' '}
          <p className='loc'>{loc.replace('/', '')}</p>
        </div>
        <div className='nav-icon' onClick={() => setOpen(!open)}>
          <div className='top'></div>
          <div className='bottom'></div>
        </div>
      </div>
    </div>
  )
}

export default withRouter(TopNav)
