import React from 'react'
import { scrollToContact, videoBaseUrl } from './../../../helper'
import SocialMediaLinks from '../../../wrappers/socialMediaLinks'

const Banner = (props) => {
  return (
    <div className='banner-section' onLoad={(a) => console.log(a)}>
      <div className='banner-wrapper'>
        <div className='banner-background-image'>
          <video playsInline autoPlay loop muted src={`${videoBaseUrl}/out.mp4`} />
        </div>
        <div className='banner-text'>
          <h1 className='banner-h1'>Thrive</h1>
          <h2 className='banner-h2'>Instead of survive</h2>
        </div>
      </div>
      <div className='banner-left'>
        <div className='text-wrapper'>
          <h1>THRIVE NATION</h1>
          <p>Ask us how we can help you to Thrive!</p>
          <button onClick={scrollToContact} className='contact-button'>
            Contact us
          </button>
        </div>
      </div>

      <div className='banner-bottom'>
        <SocialMediaLinks />
      </div>
    </div>
  )
}

export default Banner
