import React from "react";
import { faPlayCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { scrollToContact } from "./../../../helper";
import "./style.scss";
import SocialMediaLinks from "../../../wrappers/socialMediaLinks";

const Banner = props => {
    return (
        <div
            className={"banner-section " + (props.playing ? "playing" : "")}
            onLoad={a => console.log(a)}
        >
            <div className="banner-wrapper">
                <div className="banner-background-image"></div>
                <FontAwesomeIcon
                    onClick={() => props.setPlaying(true)}
                    className="icon"
                    icon={faPlayCircle}
                />
            </div>
            <div className="banner-left">
                <div className="text-wrapper">
                    <h1>
                        WE ARE PASSIONATE ABOUT EMPOWERING INSPIRING EDUCATING
                        OUR YOUTH
                    </h1>
                    <p>Ask us what we can do for you!</p>
                    <button
                        onClick={scrollToContact}
                        className="contact-button"
                    >
                        Contact us
                    </button>
                </div>
            </div>

            <div className="banner-bottom">
                <SocialMediaLinks />
            </div>
        </div>
    );
};

export default Banner;
