import React, { useEffect } from "react";
import Banner from "./banner";
import Staff from "./staff";
import Stats from "./stats";
import Benefits from "./benefits";
import Tools from "./tools";

export default props => {
    useEffect(() => {
        window.scrollTo({ top: 0 });
    }, []);
    return (
        <div className="corporatePage" style={{ width: "100%" }}>
            <Banner />
            <Staff />
            <Stats />
            <Benefits />
            <Tools />
        </div>
    );
};
