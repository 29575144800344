import React from 'react';
import './style.scss';

const GrowYourBusiness = () => {
  return (
    <div className='growYourBusinessSection'>
      <div className='right-section'>
        <div className='section-heading'>
          <h2 className='title'>
            GROW YOUR BUSINESS
            <br />
            EXPAND YOUR KNOWLEDGE
          </h2>
          <p>INTERACTIVE COACHING AND CLIENT APP WITH FULL REPORTING AND TRACKING CAPABILITY</p>
        </div>
      </div>
    </div>
  );
};

export default GrowYourBusiness;
