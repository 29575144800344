import React from "react";
import "./style.scss";

const Benefits = props => {
    return (
        <div className="benefits">
            <div className="left-section">
                <div className="section-heading">
                    <h2 className="title">SIGNIFICANT BUSINESS BENEFITS</h2>
                    <p className="">
                        A recent study has found the healthiest workers are up
                        to three times more productive than the least healthy
                        workers.
                        <br />
                        <br />
                        Healthy workers were fit, energetic and alert, compared
                        to unhealthy workers who had low energy and poor
                        concentration. Healthy people are less likely to become
                        sick with ongoing conditions (such as heart disease,
                        type 2 diabetes and some cancers) and tend to take fewer
                        sick days. They’re less likely to be fatigued so are
                        more alert to safety issues and less likely to be
                        injured.
                        <br />
                        <br />
                        They’re more likely to stay on the job for longer,
                        reducing recruitment and training costs and the
                        resulting productivity losses.
                    </p>
                </div>
            </div>
            <div className="right-section">
                <div className="box box-1">
                    <p>
                        Healthy workers worked 143 effective work hours a month,
                        compared to unhealthy workers’ 49 hours.
                    </p>
                </div>
                <div className="box box-2">
                    <p>
                        Healthy workers took two days annual sick leave,
                        compared to the 18 days unhealthy workers took.
                    </p>
                </div>
                <div className="box box-3">
                    <p>
                        In New Zealand on average employees are away from work
                        4.5 days per year, caused by ongoing health issues. This
                        costs a business between $800 and $1500 per person
                        annually
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Benefits;
