import React, { useState, useEffect } from "react";
import Banner from "./banner";
import SchoolVideo from "./schoolVideo";
import OurSchools from "./inSchools";
import ThreePs from "./threePs";
import Program from "./program";

const SchoolsPage = props => {
    const [playing, setPlaying] = useState(false);
    useEffect(() => {
        if (playing) {
            document.body.style.height = "100vh";
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.height = "auto";
            document.body.style.overflow = "auto";
        }
        return () => {
            document.body.style.height = "auto";
            document.body.style.overflow = "auto";
        };
    }, [playing]);
    useEffect(() => {
        window.scrollTo({ top: 0 });
    }, []);
    return (
        <div className="schoolsPage" style={{ width: "100%" }}>
            <Banner playing={playing} setPlaying={setPlaying} />
            <OurSchools />
            <ThreePs />
            <Program />
            <SchoolVideo playing={playing} setPlaying={setPlaying} />
        </div>
    );
};

export default SchoolsPage;
