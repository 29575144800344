import React from "react"
import "./style.scss"

const Program = (props) => {
  return (
    <div className="program">
      <div className="card">
        <div className="card-top">
          <h2 className="title">
            THRIVE NATION,
            <br /> IN SCHOOLS, IN ACTION
          </h2>
          <div className="logo"></div>
        </div>
        <div className="flex-row">
          <div className="sec">
            <h3 className="number">1</h3>
            <h3 className="title">MEET AND GREET PLUS FREE PLAY</h3>
            <p className="text">
              Kids check in with head coach and then go off to socialise with a ball game prior to session starting.
            </p>
          </div>
          <div className="sec">
            <h3 className="number">2</h3>
            <h3 className="title">
              INTRO AND WARM UP <br />
              (5 MIN)
            </h3>
            <p className="text">
              Once kids have arrived, lead trainer briefly goes through lesson plan for the day and the skill of the
              week. This is then followed by our Thrive Nation Big 10 dynamic warm up
            </p>
          </div>
        </div>
        <div className="flex-row">
          <div className="sec">
            <h3 className="number">3</h3>
            <h3 className="title">
              CARDIO ATTACK <br />
              (5 MIN)
            </h3>
            <p className="text">
              Trainer then leads a running focused activity. Running is a critical part of the physical literacy
              pathway. Its also very easy for the students to learn and apply and a great way to start the session off.
            </p>
          </div>
          <div className="sec">
            <h3 className="number">4</h3>
            <h3 className="title">
              SKILL OF THE WEEK <br />
              (10 MIN)
            </h3>
            <p className="text">
              Each week we will teach one of our 14 movement patterns Each student will learn the correct performance
              cues and how to practically perform each movement accordingly.
            </p>
          </div>
        </div>
        <div className="flex-row">
          <div className="sec">
            <h3 className="number">5</h3>
            <h3 className="title">
              GAMIFICATION SESSION <br />
              (5 MIN)
            </h3>
            <p className="text">
              Fun little finisher to leave the kids feeling engaged. Great opportunity for trainer to build team work,
              communication strategic planning.
            </p>
          </div>
          <div className="sec">
            <h3 className="number">6</h3>
            <h3 className="title">COOLDOWN &amp; WELLNESS CHECKIN (10 MIN)</h3>
            <p className="text">
              Kids check in with head coach and then go off to socialise with a ball game prior to session starting.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Program
