import React from "react"
import { faFacebookSquare, faInstagram } from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const SocialMediaLinks = (props) => {
  return (
    <div className="social-media-links">
      <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/athletenationnz/">
        <FontAwesomeIcon className="icon" icon={faInstagram} />
      </a>
      <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/athletenationnz">
        <FontAwesomeIcon className="icon" icon={faFacebookSquare} />
      </a>
    </div>
  )
}

export default SocialMediaLinks
