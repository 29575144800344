import React from "react";
import Banner from "./banner";
import WhatIsANSection from "./whatIsANSection";
import IntegratedApproachSection from "./integratedApproachSection";
import ClientsSection from "./clientsSection";

const HomePage = props => {
    return (
        <div className="homePage">
            <Banner />
            <WhatIsANSection />
            <IntegratedApproachSection />
            <ClientsSection />
        </div>
    );
};

export default HomePage;
