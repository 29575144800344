import React from "react";
import "./style.scss";

const ThreePs = props => {
    return (
        <div className="threePs">
            <div className="left-section">
                <div className="section-heading">
                    <h2 className="title">
                        OUR PROGRAM CURRICULUM ALSO ALIGNS WITH THE 3 P's OF TE
                        TIRITI
                    </h2>
                    <p className="white-text text-left">
                        The principles of partnership, participation and
                        protection underpin the relationship between the
                        Government and Māori under the Treaty of Waitangi.
                        <br />
                        <br />
                        Partnership involves working together with iwi, hapū,
                        whānau and Māori communities to develop strategies for
                        Māori health gain and appropriate health and disability
                        services.
                        <br />
                        <br />
                        Participation requires Māori to be involved at all
                        levels of the health and disability sector, including in
                        decision-making, planning, development and delivery of
                        health and disability services.
                        <br />
                        <br />
                        Protection involves the Government working to ensure
                        Māori have at least the same level of health as
                        non-Māori, and safeguarding Māori cultural concepts,
                        values and practices.
                    </p>
                </div>
            </div>
            <div className="right-section">
                <div className="box box-1">
                    <div className="title">
                        <p>Partnership</p>
                    </div>
                </div>
                <div className="box box-2">
                    <div className="title">
                        <p>Participation</p>
                    </div>
                </div>
                <div className="box box-3">
                    <div className="title">
                        <p>Protection</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ThreePs;
