import React from "react"
import "./style.scss"
import { Link } from "react-router-dom"

const Footer = (props) => {
  return (
    <div className="footer">
      <div className="wrapper">
        <div className="left-section">
          <h1>Thrive nation</h1>
        </div>
        <div className="links">
          <Link className="link" to={"/"}>
            Home
          </Link>
          <Link className="link" to={"/schools"}>
            Schools
          </Link>
          <Link className="link" to={"/corporate"}>
            Corporate
          </Link>
          <Link className="link" to={"/courses"}>
            Courses
          </Link>
        </div>
        <p>&copy;{new Date().getFullYear()} Thrive Nation</p>
        <p className="created">Website by Samrox Ltd.</p>
      </div>
    </div>
  )
}

export default Footer
