export const scrollToContact = () => {
  window.scrollTo({
    top: document.getElementById('contactSection').offsetTop - 70,
    behavior: 'smooth',
  })
}

export const videoBaseUrl = 'https://thrivenation.s3.amazonaws.com/videos'
export const imageBaseUrl = 'https://d3bfxs37wxuorx.cloudfront.net'
export const imageRequest = (image, width, height) =>
  image
    ? `${imageBaseUrl}/${btoa(
        JSON.stringify({
          bucket: 'thrivenation',
          key: image,
          edits: {
            resize: {
              width,
              height,
            },
          },
        })
      )}`
    : ''
