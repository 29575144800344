import React, { useState, useImperativeHandle, forwardRef } from "react";
import PropTypes from "prop-types";
import {
    faExclamationTriangle,
    faChevronDown
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./style.scss";

const SelectWrapper = forwardRef((props, ref) => {
    const [error, setError] = useState(null);
    const [open, setOpen] = useState(false);

    const valueChanged = val => {
        props.onChange(val);
        validate(val);
    };

    useImperativeHandle(ref, () => ({
        validateInput: v => {
            return validate(v);
        }
    }));

    const validate = val => {
        if (props.required && !val) {
            setError("Required");
            return false;
        }
        setError("");
        return true;
    };

    return (
        <div className={"select-wrapper " + (error && "error")}>
            <label htmlFor={props.name} className="label">
                {props.label}
            </label>
            <div className="input" onClick={() => setOpen(!open)}>
                <p>{props.value}</p>
                <FontAwesomeIcon className="down-icon" icon={faChevronDown} />
            </div>
            <div className={"select-options " + (open && "open")}>
                {props.options.map(item => (
                    <div
                        key={item}
                        className="option"
                        onClick={() => {
                            valueChanged(item);
                            setOpen(!open);
                        }}
                    >
                        <p>{item}</p>
                    </div>
                ))}
            </div>
            {error ? (
                <div className="error">
                    <span className="error-text">{error}</span>
                    <FontAwesomeIcon
                        className="error-icon"
                        icon={faExclamationTriangle}
                    />
                </div>
            ) : null}
        </div>
    );
});

SelectWrapper.defaultProps = {
    inputType: "text"
};

SelectWrapper.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    type: PropTypes.string,
    placeholder: PropTypes.any,
    value: PropTypes.any,
    required: PropTypes.bool,
    onChange: PropTypes.func.isRequired
};

export default SelectWrapper;
