import React from "react";
import "./style.scss";
const Fusion = props => {
    return (
        <div className="stats">
            <div className="section-heading">
                <h1 className="title white-text">THE STATISTICS DON'T LIE</h1>
                <div className="stats-wrapper">
                    <div className="flex-row">
                        <div className="stat stress">
                            <h3 className="stat-title">Stress</h3>
                            <div className="bar-wrapper">
                                <div className="bar">
                                    <div className="bar-1 text">
                                        <p>
                                            Employee Stress is up 18% across the
                                            corporate sector
                                        </p>
                                    </div>
                                    <div className="bar-2 percent">
                                        <p>18%</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="stat md">
                            <h3 className="stat-title">Mental Distress</h3>
                            <div className="bar-wrapper">
                                <div className="bar">
                                    <div className="bar-1 percent">
                                        <p>23%</p>
                                    </div>
                                    <div className="bar-2 text">
                                        23% of adults report experiencing a
                                        mental distress at least once a month.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex-row">
                        <div className="stat obese">
                            <h3 className="stat-title">Obesity</h3>
                            <div className="bar-wrapper">
                                <div className="bar">
                                    <div className="bar-1 percent">
                                        <p>32%</p>
                                    </div>
                                    <div className="bar-2 text">
                                        32% OF New Zealand Adults are obese
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="stat activity">
                            <h3 className="stat-title">Inactivity</h3>
                            <div className="bar-wrapper">
                                <div className="bar">
                                    <div className="bar-1 percent">
                                        <p>45%</p>
                                    </div>
                                    <div className="bar-2 text">
                                        45% of adults do less than 30mins of
                                        physical activity duringa 7 day period.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Fusion;
