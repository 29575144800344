import React from "react";
import {
    faFacebookSquare,
    faInstagram
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import "./style.scss";

const ContactInfo = props => {
    return (
        <div className="contact-info">
            <h2>Contact Us</h2>
            <p>
                We are here to answer any questions you have. Learn more about
                how we can help you, whether you represent a school, business,
                or yourself.
            </p>
            <div className="main-info">
                <p>Brett Hollister</p>
                <div className="info-row">
                    <FontAwesomeIcon className="icon" icon={faPhone} />
                    <p> +64 274 960 119</p>
                </div>
                <div className="info-row">
                    <FontAwesomeIcon className="icon" icon={faEnvelope} />
                    <p>Email : Info@athletenation.co.nz</p>
                </div>
                <div className="social-media-links">
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.instagram.com/explore/locations/342167512/"
                    >
                        <FontAwesomeIcon className="icon" icon={faInstagram} />
                    </a>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.facebook.com/athletenationnz"
                    >
                        <FontAwesomeIcon
                            className="icon"
                            icon={faFacebookSquare}
                        />
                    </a>
                </div>
            </div>
        </div>
    );
};

export default ContactInfo;
