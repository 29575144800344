import React from "react"
import "./style.scss"
const Fusion = (props) => {
  return (
    <div className="tools">
      <div className="left-section"></div>
      <div className="right-section">
        <div className="section-heading">
          <h1 className="title">Tools to Educate, Track and Monitor positive change</h1>
          <p className="white-text">
            The Thrive Nation Corporate App ensures that we are able to deliver education in the 10 important areas of
            human performance like Sleep, Nutrition, Hydration, Mindfulness and other important functions. Our
            confidential easy check in process quickly builds your staff knowledge and awareness, monitoring positive
            change and reporting outcomes.
            <br />
            <br />
            Help your employees Thrive Instead of just Surviving at Work, at Home and in their personal life
            <br />
            <br />A healthier workforce positively impacts of company culture and creates long term loyal employees who
            champion the company to others.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Fusion
