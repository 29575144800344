import React from "react"
import { faQuoteLeft } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { scrollToContact } from "./../../../helper"
import "./style.scss"

const ClientsSeection = () => {
  return (
    <div className="clientsSection">
      <div className="left-section">
        <div className="section-heading">
          <h2 className="title">WHY THRIVE NATION</h2>
          <p className="text-left">
            Thrive Nation is revolutionising the Sport, Fitness and Health Industry as we know it. We are the first
            organisation in the world (that we know of) to Systemise Athleticism with a transparent numerical scoring
            system. We teach correct movement, progressing to bodyweight only exercises, then moving onto Load, Explode
            and Control. We have developed regressions throughout our system to ensure that we cater for all levels of
            athleticism. We strongly believe that human performance is made up of 3 components; Mind, Body &amp; Energy.
            We educate, monitor and support development in important human performance areas like Grit, Resilience,
            Mindfulness, Breathing, Hydration, Sleep, Nutrition, Recovery, Connection,
            <br />
            <br />
            In a world of declining physical health and wellness, Thrive Nation provides the knowledge, education and
            tools to ensure our customers "Thrive Instead of Survive"
          </p>
          <p className="text-left">
            Whether you are a School, Business or Gym, a Teacher, Personal Trainer, Sports Coach, or Wellness
            Professional we have a system that delivers measurable outcomes!
            <br />
            <br />
            Ask us how we can help you to Thrive!
          </p>
          <button onClick={scrollToContact} className="contact-button dark">
            Contact us
          </button>
        </div>
      </div>
      <div className="right-section">
        <div className="card">
          <div className="emblem mhs"></div>
          <div className="">
            <FontAwesomeIcon className="icon" icon={faQuoteLeft} />
            <p className="quote-text">
              Thrive Nation has been an integral part of our Health and Well-being programme at Massey High School. The
              programme is designed to suit everyone, from those that are starting the journey to fit and healthy to
              those that are playing for New Zealand. There is a genuine commitment to put people at the heart of the
              programme and a refreshing holistic approach to the whole person.
            </p>

            <p className="quote-by">
              <b>Glen Denham</b>
              <br />
              Principal Massey High School - (Former Tall Black Captain)
            </p>
          </div>
        </div>

        <div className="card">
          <div className="emblem hbhs"></div>
          <div className="">
            <FontAwesomeIcon className="icon" icon={faQuoteLeft} />
            <p className="quote-text">
              I have been involved with youth sport all my life and as Teacher of Physical Education and Coach of sports
              teams since the late 70’s. The Thrive Nation programme of training young people to become more athletic
              rather than become good athletes has been a revelation. They are learning to use their bodies correctly
              and can train more specifically with result of actual rowing improvement. Also, the boys are experiencing
              more enjoyment and satisfaction than ever before when training.
            </p>

            <p className="quote-by">
              <b>Glen Ross</b>
              <br />
              Director of Rowing Hamilton Boys High School
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ClientsSeection
