import React, { useState, useRef, useEffect } from 'react'
import bannerPoster from './../../../assets/graphics/schools-banner.jpg'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { videoBaseUrl } from '../../../helper'
import './style.scss'

const SchoolVideo = (props) => {
  const videoRef = useRef(null)
  const [width, setWidth] = useState(0)

  useEffect(() => {
    updateWindowDimensions()
    window.addEventListener('resize', updateWindowDimensions)
    window.scrollTo({ top: 0 })
    return () => {
      window.removeEventListener('resize', updateWindowDimensions)
    }
  }, [])

  const updateWindowDimensions = () => {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    if (props.playing) videoRef.current.play()
    else videoRef.current.pause()
  }, [props.playing])

  let videoName = width < 500 ? '/sm/' : width < 780 ? '/md/' : width < 1000 ? '/lg/' : '/xl/'
  videoName += 'schools.mp4'

  return (
    <div className={'video-wrapper ' + (props.playing ? 'playing' : '')}>
      <video controls ref={videoRef} poster={bannerPoster} src={`${videoBaseUrl}${videoName}`} />
      <FontAwesomeIcon onClick={() => props.setPlaying(!props.playing)} icon={faTimes} className='icon' />
    </div>
  )
}

export default SchoolVideo
