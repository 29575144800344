import React from "react"

const Fusion = (props) => {
  return (
    <div className="plain-white-section" onLoad={(a) => console.log(a)}>
      <div className="section-heading">
        <h1 className="title">THRIVE NATION COACHES AND SUPPORTS YOUR STAFF TO THRIVE INSTEAD OF SURVIVE</h1>
        <p className="text-left">
          Our unique integrated MIND BODY ENERGY program has been specifically designed to prepare your staff to handle
          the physical and mental demands of daily work life and life. Our World Class system promotes positive changes
          in three critical areas of human performance Mind – Body and Energy. We educate and train your staff on the
          key factors that allow them to live in a Thrive state, every minute of every day. By “Thrive” state, we ensure
          that their performance is at an optimal level, improving energy, enthusiasm and productivity. In addition,
          your staff are more alert, less stressed with a clearer focus. If there is a requirement for a level of
          physical readiness needed in a particular role then we can ensure your staff have the physical capability
          needed for any task thrown at them.
        </p>
      </div>
    </div>
  )
}

export default Fusion
