import React, { useState, useRef } from 'react'
import InputWrapper from '../../../components/inputWrapper'
import SelectWrapper from '../../../components/selectWrapper'
import TextareaWrapper from '../../../components/textareaWrapper'
import spinner from '../../../assets/graphics/spinner.gif'
import axios from 'axios'
import './style.scss'

const Form = (props) => {
  const [name, setName] = useState('')
  const [subject, setSubject] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const nameRef = useRef(null)
  const subjectRef = useRef(null)
  const emailRef = useRef(null)
  const messageRef = useRef(null)

  const [messageSent, setMessageSent] = useState(false)
  const [loading, setLoading] = useState(false)

  const sendMessage = async (e) => {
    e.preventDefault()
    if (
      nameRef.current.validateInput(name) &&
      emailRef.current.validateInput(email) &&
      subjectRef.current.validateInput(subject) &&
      messageRef.current.validateInput(message)
    ) {
      setLoading(true)
      axios
        .post('https://api.thrivenation.co.nz/website/contact_form', {
          name,
          email,
          subject,
          message,
        })
        .then(({ data }) => {
          if (data.state) {
            setLoading(false)
            setMessageSent(true)
          } else {
            setLoading(false)
          }
        })
        .catch((err) => {
          setLoading(false)
        })
    }
  }

  return (
    <form className="form" onSubmit={sendMessage}>
      <div className={'form-wrapper ' + (messageSent ? 'sent' : '')}>
        <div className="form-left">
          <h2>Message Us</h2>
          <InputWrapper label="Name" name="name" ref={nameRef} value={name} required onChange={setName} />
          <InputWrapper label="Email" name="email" ref={emailRef} value={email} required onChange={setEmail} />
          <SelectWrapper
            label="Subject"
            name="subject"
            ref={subjectRef}
            value={subject}
            options={['Schools', 'Corporate', 'Personal Trainer', 'Other']}
            required
            onChange={setSubject}
          />
          <TextareaWrapper
            label="Message"
            name="message"
            ref={messageRef}
            value={message}
            required
            onChange={setMessage}
          />
          <button onClick={sendMessage} className="contact-button teal">
            Send
          </button>

          {loading ? (
            <div className="message-loading">
              <img src={spinner} alt="spinner" className="spinner" />
            </div>
          ) : null}
        </div>
        <div className="form-right">
          <p className="thanks">Thank you for your message!</p>
          <p>We will get back to you as soon as we can.</p>
        </div>
      </div>
    </form>
  )
}

export default Form
