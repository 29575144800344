import React from "react";
import Form from "./form";
import ContactInfo from "./contactInfo";
import "./style.scss";

const Contact = props => {
    return (
        <div className="contactSection" id="contactSection">
            <div className="wrapper">
                <div className="left-section">
                    <ContactInfo />
                </div>
                <div className="right-section">
                    <Form />
                </div>
            </div>
        </div>
    );
};

export default Contact;
