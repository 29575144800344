import React from "react";
import { scrollToContact } from "./../../../helper";
import SocialMediaLinks from "../../../wrappers/socialMediaLinks";

const Banner = (props) => {
    return (
        <div className="banner-section" onLoad={(a) => console.log(a)}>
            <div className="banner-wrapper">
                <div className="banner-background-image"></div>
            </div>
            <div className="banner-left">
                <div className="text-wrapper">
                    <h1>WORLD CLASS KNOWLEDGE IN YOUR HANDS</h1>
                    <p>Learn more about our courses by getting in touch</p>
                    <button
                        onClick={scrollToContact}
                        className="contact-button"
                    >
                        Contact us
                    </button>
                </div>
            </div>

            <div className="banner-bottom">
                <SocialMediaLinks />
            </div>
        </div>
    );
};

export default Banner;
